import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import {
  mainBlue,
  mainWhite,
  mainGrey,
  mainOrange,
  screen,
  transHover,
} from "../components/common/variables";
import styled, { keyframes } from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import SEO from "../components/seo";
import PageTitle from "../components/page-title";
import "@brainhubeu/react-carousel/lib/style.css";
import LabeledArrow from "../components/labeled-arrow";
import { IoMdClose } from "react-icons/io";

const Wrapper = styled.div`
  padding-bottom: 110px;
  padding-top: 73px;
  @media ${screen.xsmall} {
    padding-top: 87px;
    padding-bottom: 320px;
  }
  @media ${screen.small} {
    padding-top: 80px;
  }

  .banner-sm {
    @media ${screen.xsmall} {
      display: none;
    }
  }

  .contessi-introduction {
    color: ${mainBlue};
    display: flex;
    padding-left: 35px;
    padding-right: 35px;
    padding-top: 35px;
    position: relative;
    z-index: 2;
    @media ${screen.xsmall} {
      padding-left: 0;
      padding-right: 70px;
      padding-top: 0;
    }
    @media ${screen.small} {
      padding-top: 82px;
      padding-left: 50px;
      padding-right: 50px;
    }
    @media ${screen.medium} {
      padding-top: 100px;
      padding-left: 160px;
      padding-right: 160px;
    }
    @media ${screen.large} {
    }
    @media ${screen.xlarge} {
      padding-top: 140px;
      padding-left: 280px;
      padding-right: 280px;
    }

    &__img {
      width: 100%;
      max-width: 600px;
      margin-right: 95px;
      margin-top: 75px;
      display: none;
      @media ${screen.xsmall} {
        margin-right: 40px;
        margin-top: 40px;
        display: block;
      }
      @media ${screen.small} {
        margin-right: 95px;
        margin-top: 75px;
      }
      @media ${screen.xlarge} {
        margin-top: 120px;
      }
    }

    &__text {
      width: 100%;
      @media ${screen.xsmall} {
        margin-top: 140px;
      }
      @media ${screen.small} {
        margin-top: 0;
      }

      .heading {
        line-height: 1.25;
        white-space: pre-wrap;
      }

      .description {
        max-width: 503px;
        margin: 20px 0 0 0;
        @media ${screen.xlarge} {
          margin: 25px 0 0 0;
        }

        p {
          font-weight: 300;
          font-size: 1.17rem;
          margin-bottom: 18px;
          @media ${screen.xsmall} {
            font-size: 1.4rem;
            margin-bottom: 22px;
          }
          @media ${screen.xlarge} {
            font-size: 1.5rem;
            margin-bottom: 30px;
          }
          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .hash-link {
        margin-top: 50px;
        cursor: pointer;
        display: flex;
        color: ${mainGrey};
        font-size: 1rem;
        font-weight: 800;
        transition: ${transHover};
        flex-direction: column;
        align-items: flex-start;
        padding-right: 50px;
        line-height: 1.6;
        @media ${screen.xsmall} {
          font-size: 1.05rem;
          align-items: center;
          flex-direction: row;
          padding-right: 0;
          align-items: center;
        }
        @media ${screen.small} {
          margin-bottom: 80px;
        }
        @media ${screen.large} {
          margin-bottom: 0;
        }
        @media ${screen.xlarge} {
          font-size: 1.1rem;
          margin-top: 60px;
        }

        svg {
          width: 72px;
          margin-left: 0;
          margin-top: 15px;
          @media ${screen.xsmall} {
            margin-left: 15px;
            margin-top: 0;
          }

          .right-arrow {
            transition: ${transHover};
          }
        }

        @media ${screen.withCursor} {
          &:hover {
            color: ${mainOrange};
          }
          &:hover .right-arrow {
            stroke: ${mainOrange};
          }
        }
      }

      .equipment {
        margin: 55px 0 0 0;
        @media ${screen.xsmall} {
          margin: 100px 0 0 0;
        }

        &__heading {
          font-size: 1.7rem;
          margin-bottom: 10px;
          line-height: 1.34;
          max-width: 500px;
          @media ${screen.xsmall} {
            font-size: 2.3rem;
            margin-bottom: 14px;
          }
          @media ${screen.xlarge} {
            font-size: 2.5rem;
            margin-bottom: 16px;
          }
        }

        &__img {
          margin: 15px 0 0 0;
          max-width: 400px;
          cursor: zoom-in;
          @media ${screen.xsmall} {
            margin: 25px 0 0 0;
          }
        }
      }
    }
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(65px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Lightbox = styled.div`
  background: rgba(0, 35, 94, 0.71);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;

  #lightbox-img {
    width: 100%;
    max-width: 1000px;
    position: relative;
    opacity: 0;
    transform: translateY(65px);
    animation: ${fadeIn} 0.21s ease-out forwards;
    margin: 0 20px;
    @media ${screen.small} {
      max-width: calc(575px + 24vw);
    }
    @media ${screen.xlarge} {
      max-width: 1300px;
    }

    .close-lightbox-icon {
      color: ${mainWhite};
      cursor: pointer;
      font-size: 2.5rem;
      position: absolute;
      top: 0;
      right: 0;
      transition: ${transHover};
      z-index: 10;
      background: ${mainBlue};
      line-height: 32px;
      @media ${screen.xsmall} {
        top: 0;
        right: 0;
      }
      @media ${screen.small} {
        top: 0;
        right: 0;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainOrange};
        }
      }
    }
  }
`;

const WhatIsThermicLancePage = ({ data }) => {
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const {
    main_heading,
    main_description,
    featured_image,
    featured_image_mobile,
    title_tag,
    meta_description,
    equipment_image,
    equipment_heading,
  } = data.main.data;

  const toggleLightbox = () => {
    setLightboxOpen(!isLightboxOpen);
  };

  useEffect(() => {
    const userClick = (e) => {
      if (e.target.id === "lightbox") {
        setLightboxOpen(false);
      }
    };
    document.addEventListener("click", userClick);

    return () => {
      document.removeEventListener("click", userClick);
    };
  }, []);

  return (
    <Layout>
      <SEO title={title_tag} description={meta_description} />
      <Wrapper>
        <div className="banner-sm">
          {featured_image_mobile.gatsbyImageData && (
            <GatsbyImage
              image={featured_image_mobile.gatsbyImageData}
              alt={featured_image.alt}
            />
          )}
        </div>

        <div className="contessi-introduction">
          <div className="contessi-introduction__img">
            <div className="featured">
              {featured_image.gatsbyImageData && (
                <GatsbyImage
                  image={featured_image.gatsbyImageData}
                  alt={featured_image.alt}
                />
              )}
            </div>
          </div>
          <div className="contessi-introduction__text">
            <PageTitle className="heading">{main_heading}</PageTitle>

            <div
              className="description"
              dangerouslySetInnerHTML={{ __html: main_description.html }}
            />
            <LabeledArrow
              className="hash-link"
              label={`SEE OUR CONTESSI RANGE`}
              url={`/contessi-products/`}
            />
            <div className="equipment">
              <h2 className="equipment__heading">{equipment_heading}</h2>
              <div className="equipment__img" onClick={toggleLightbox}>
                <GatsbyImage
                  image={equipment_image.gatsbyImageData}
                  alt={equipment_image.alt}
                />
              </div>
            </div>
          </div>
        </div>
      </Wrapper>

      {isLightboxOpen && (
        <Lightbox id="lightbox">
          <div id="lightbox-img">
            <span className="close-lightbox-icon" onClick={toggleLightbox}>
              <IoMdClose />
            </span>
            {featured_image.gatsbyImageData && (
              <GatsbyImage
                image={equipment_image.gatsbyImageData}
                alt={equipment_image.alt || "Preview"}
              />
            )}
          </div>
        </Lightbox>
      )}
    </Layout>
  );
};

export default WhatIsThermicLancePage;

export const dataQuery = graphql`
  {
    main: prismicWhatsContessiPage {
      data {
        main_heading
        title_tag
        meta_description
        main_description {
          html
        }
        featured_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        featured_image_mobile {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
        equipment_heading
        equipment_image {
          alt
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`;
